* {
  font-family: 'Ubuntu', sans-serif;
}

.starter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 10vw;
  padding: 20px;
  box-sizing: border-box;
  background: #263238;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.logo {
  display: block;
  height: 100px;
  margin-bottom: 50px;
}
